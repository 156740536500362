import * as apifetch from './fetch.js';
import * as formats from '../utilities/formats.js';

export async function getSearchCount(search_term, section, page_view, sku_soon) {
    let term = formats.removeSpaces(search_term);
    term = formats.replaceChars(term);
    const urlEndpoint = '/v2/search/' + term + '/count';

    const postData = {
        shop: "SHOP_ALL",
        page_view: page_view,
        section: section,
        stock_priority: 0,
        sku_soon: sku_soon
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getSearchCountSB(search_term, section, page_view, sku_soon) {
    let term = formats.removeSpaces(search_term);
    term = formats.replaceChars(term);
    const urlEndpoint = '/v2/search/' + term + '/count';

    const postData = {
        shop: 'SHOP_SB',
        page_view: page_view,
        section: section,
        stock_priority: 0,
        sku_soon: sku_soon
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getSearchResults(shop, search_term, section, page_view, stock_priority, sku_soon) {
    let term = formats.removeSpaces(search_term);
    term = formats.replaceChars(term);
    const urlEndpoint = '/v2/search/' + term;

    const postData = {
        shop: "SHOP_ALL",
        page_view: page_view,
        section: section,
        stock_priority: stock_priority,
        sku_soon: sku_soon
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export function getSearchResultsWithAbort(shop, search_term, section, page_view, stock_priority, sku_soon, controller, signal) {
    let term = formats.removeSpaces(search_term);
    term = formats.replaceChars(term);
    const urlEndpoint = '/v2/search/' + term;

    const postData = {
        shop: "SHOP_ALL",
        page_view: page_view,
        section: section,
        stock_priority: stock_priority,
        sku_soon: sku_soon
    }

    return apifetch.abortFetch(urlEndpoint, 'POST', postData, controller, signal);
}

export async function getSearchResultsSB(shop, search_term, section, page_view, stock_priority, sku_soon) {
    let term = formats.removeSpaces(search_term);
    term = formats.replaceChars(term);
    const urlEndpoint = '/v2/search/' + term;

    const postData = {
        shop: 'SHOP_SB',
        page_view: page_view,
        section: section,
        stock_priority: stock_priority,
        sku_soon: sku_soon
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getElevatedSku(sku) {
    let term = 'elevated';
    const urlEndpoint = '/v2/search/' + term + '/' + sku;

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function getSearchIntentions(search_term) {
    const urlEndpoint = '/search/getSearchIntentions.php';

    const postData = {
        search_term: search_term
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function saveSearchQuery(search_term) {
    const urlEndpoint = '/search/saveSearchQuery.php';

    const postData = {
        search_term: search_term
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getSearchResultsNewsletter(value) {
    const urlEndpoint = '/v2/newsletters/search/' + value;

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function getSearchTitle(term, shop) {
     const urlEndpoint = '/v2/search/' + term + '/title/' + shop;

    return apifetch.sbFetch(urlEndpoint, 'GET');
}