import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import i18n from "i18next";
import moment from "moment";
import { withTranslation } from 'react-i18next';

const BlockBookStock = inject("stores") (
    observer (
        class BlockBookStock extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.state = {
                    feedbackStock: '',
                    stock_translation: ''
                }

                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeStock = this.props.stores.storeStock;
            }

            componentDidMount = async() => {
                const onyx_list = 65;
                const stock_code = this.props.book.stock_code;

                const avail = await this.storeStock.checkStockAvailability('order', this.props.book)
                const feedbackStock = await this.storeStock.getFeedbackStock(this.props.book);

                this.setState({
                    feedbackStock: feedbackStock,
                    avail:  avail
                })
            }

            componentDidUpdate = async(prevProps) => {
                const onyx_list = 65;
                const stock_code = this.props.book.stock_code;

                let avail;
                let feedbackStock;
                
                // if (prevProps.sku !== this.props.sku) {   
                //     avail = await this.storeStock.checkStockAvailability('order', this.props.book)
                //     feedbackStock = await this.storeStock.getFeedbackStock(this.props.book);

                //     this.setState({
                //         feedbackStock: feedbackStock,
                //         avail:  avail
                //     })
                // }

                if (prevProps.book !== this.props.book || prevProps.stock_code !== this.props.stock_code) {  
                    avail = await this.storeStock.checkStockAvailability('order', this.props.book)
                    feedbackStock = await this.storeStock.getFeedbackStock(this.props.book);

                    this.setState({
                        feedbackStock: feedbackStock,
                        avail: avail
                    })
                }
            }

            // checkReleaseDate = (releaseDate) => {
            //     let isAfter = false;
            //     var currentDate = moment().format("DD MMMM YYYY");

            //     if (moment(currentDate).isBefore(moment(releaseDate.date))) {
            //         isAfter = true;
            //     }

            //     return isAfter;
            // }
  
            render() {
                return (
                    <div className="block-content--book__delivery">
                        {parseInt(this.props.book.stock_code) === 10 || parseInt(this.props.book.stock_code) === 32
                        ?   <span 
                                className="book-delivery--expected"
                            >
                                {this.state.feedbackStock}
                                {((parseInt(this.props.book.stock_code) === 10 || parseInt(this.props.book.stock_code) === 32) && this.state.avail) &&
                                    ", nu te bestellen"
                                }
                            </span>
                        :   <span>{this.state.feedbackStock}</span>
                        }

                        {this.props.children}
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(BlockBookStock);


