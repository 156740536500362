import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import i18n from "i18next";

import ButtonPagingBack from '../../elements/button-paging-back';
import ButtonPagingFBack from '../../elements/button-paging-fback';
import ButtonPagingForward from '../../elements/button-paging-forward';
import ButtonPagingFForward from '../../elements/button-paging-fforward';

const BlockPaginationPages = inject("stores") (
    observer (
        class BlockPaginationPages extends Component {

            static defaultProps = {
                pageRangeDisplayed: 6,
                rangeFrom: 1,
                rangeTo: 6
            }

            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                    rangeFrom: this.props.rangeFrom,
                    rangeTo: this.props.rangeTo
                }

                this.storeUi = this.props.stores.storeUi;
                this.storeProducts = this.props.stores.storeProducts;
            }

            componentDidMount = () => {
                this.setState({
                    page: this.props.page,
                    total: this.props.total
                })
            }

            componentDidUpdate = (prevProps) => {
                if (prevProps.total !== this.props.total) {
                    this.setState({
                        total: this.props.total
                    })
                }
                if (prevProps.page !== this.props.page) {
                    this.setState({
                        page: this.props.page
                    })
                }
            }

            // gotoPrev() {
            //     if (this.state.page > 1 && this.state.page <= this.state.rangeFrom) {
            //         this.setState({
            //             rangeFrom:  this.state.rangeFrom - 1,
            //             rangeTo: this.state.rangeTo - 1
            //         })
            //     }
            //     if (this.state.page > 1) {
            //         this.props.setPage(this.state.page - 1, this.state.rangeFrom - 1, this.state.rangeTo - 1);
            //     }
            // }

            gotoFPrev() {

            }

            // gotoNext(e, pages) {
            //     if (this.state.page >= this.state.rangeTo && this.state.page < pages) {
            //         this.setState({
            //             rangeFrom:  this.state.rangeFrom + 1,
            //             rangeTo: this.state.rangeTo + 1
            //         })
            //     }
            //     this.props.setPage(this.state.page + 1, this.state.rangeFrom + 1, this.state.rangeTo + 1);
            // }

            gotoFNext() {

            }
  
            render() {
                const total = this.state.total;
                //const total = this.storeUi.count_total;

                const inview = this.storeUi.page_view.inview;
                let pages = Math.ceil(parseInt(total) / parseInt(inview));

                // const prevPageLink = (pages > this.props.pageRangeDisplayed && this.state.page !== 1) ? true : false;
                // const nextPageLink = (pages > this.props.pageRangeDisplayed && this.state.page !== pages) ? true : false;
              
                // const fprevPageLink = (pages > this.props.pageRangeDisplayed && this.state.page !== 1) ? true : false;
                // const fnextPageLink = (pages > this.props.pageRangeDisplayed && this.state.page !== pages && this.storeUi.page_view.rangeTo < pages) ? true : false;    

                const Pages = () => {
                    let content = [];

                    for (let i = 1; i <= pages; i++) {
                        if (i >= this.storeUi.page_view.rangeFrom && i <= this.storeUi.page_view.rangeTo) {
                            content.push( 
                                <li 
                                    className={"block--pagination__listitem " + ((i !== this.storeUi.page_view.page) ? ' --link' : '') + ((i == this.storeUi.page_view.page) ? ' --active' : '') } 
                                    onClick={(e) => this.props.setPage(i, this.state.rangeFrom, this.state.rangeTo, this.storeUi.page_view.page)}>{i}</li>
                            );
                        }

                        
                    }
                    return content;
                }

                return (
                    <React.Fragment>
                        <li className="block--pagination__actions --link" onClick={(e) =>  this.gotoFPrev(e)}>
                            <ButtonPagingFBack
                                type = {this.props.type}
                                pages = {pages}
                                page = {this.state.page}
                                displayed = {this.props.pageRangeDisplayed}
                                setPage = {this.props.setRange}
                                inview = {this.storeUi.page_view.inview}
                            />
                        </li>

                        <li className="block--pagination__actions --link">
                            <ButtonPagingBack
                                type = {this.props.type}
                                pages = {pages}
                                page = {this.state.page}
                                displayed = {this.props.pageRangeDisplayed}
                                setPage = {this.props.setRange}
                                inview = {this.storeUi.page_view.inview}
                            />
                        </li>
  
                        <Pages />
                    
                        <li className="block--pagination__actions --link">
                            <ButtonPagingForward
                                type = {this.props.type}
                                pages = {pages}
                                page = {this.storeUi.page_view.page}
                                displayed = {this.props.pageRangeDisplayed}
                                setPage = {this.props.setRange}
                                inview = {this.storeUi.page_view.inview}
                            />
                        </li>
                        
                        <li className="block--pagination__actions --link" onClick={(e) => this.gotoFNext(e, pages)}>
                            <ButtonPagingFForward
                                type = {this.props.type}
                                pages = {pages}
                                page = {this.state.page}
                                displayed = {this.props.pageRangeDisplayed}
                                setPage = {this.props.setRange}
                                inview = {this.storeUi.page_view.inview}
                            />
                        </li>
                        
                    </React.Fragment>
                )
            }
        }
    )
)

export default BlockPaginationPages;

