import { observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as search from '../communicator/search';

class StoreSearch {
    searchResultsTitle = false
    searchViewPage = 1
    searchViewPos = 0

    constructor() {
    }

    async getSearchTitle(term, shop) {
        let result;
        if (term !== undefined) {
            try {
                const returnData = await search.getSearchTitle(term, shop);
                runInAction(() => {
                    result = returnData.data[0];
                    return result;
                })
            } catch (error) {
                runInAction(() => {
                    this.state = "error"
                })
            }
        } else {
            result = {};
        }
        return result;
    }

    setSearchResultsTitle(value) {
        this.searchResultsTitle = value;
    }

    setSearchViewPage(value) {
        this.searchViewPage = value;
    }

    setSearchViewPos(value) {
        this.searchViewPos = value;
    }

    getSearchResultsTitle() {
        return this.searchResultsTitle;
    }

    getSearchViewPage() {
        return this.searchViewPage;
    }

    getSearchViewPos() {
        return this.searchViewPos;
    }
}

decorate(StoreSearch, {
    searchResultsTitle: observable,
    searchViewPage: observable,
    searchViewPos: observable,
    getSearchTitle: action
})

export default StoreSearch;