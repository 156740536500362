import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import {
    isBrowser,
    isMobile,
    isIOS
} from "react-device-detect";
import MetaTags from 'react-meta-tags';
import queryString from 'query-string';
import * as rdd from "react-device-detect";

import * as ui from '../utilities/ui';
import * as content from '../utilities/content';

import SidebarLeft from '../components/grid/sidebar-left';
import SidebarRight from '../components/grid/sidebar-right';
import PageHeader from '../elements/page-header';
import ScrollerTop from '../elements/scroller-top';
import ButtonBack from '../elements/button-back';
import BlockBookActions from '../components/block/block-book-actions';
import SearchOverlay from '../components/search/search-overlay';
//import SearchResultOverlay from '../components/search/search-result-overlay';

const Template = inject("stores") (
    observer (
        class Template extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;

                this.state = {
                    icon: '',
                    isSticky: false
                }
            }

            componentDidMount() {
                if (this.props.reset !== false) {
                    this.storeUi.setActiveSubCategory('');
                    this.storeUi.setActiveCategory('');
                }

                this.setNavTab();

                if (this.props.useScroll && this.storeUi.freezeScrolling && this.storeUi.scrollPos !== 0) {
                    //ui.smoothScrollTo(0, this.storeUi.scrollPos, 500); 
                    window.scrollTo(0, this.storeUi.scrollPos);
                } else {
                    //ui.smoothScrollTo(0, 0, 500); 
                }

                this.changeFavIcon();

                this.props.sticky && document.addEventListener("scroll", () => {
                    this.handleScroll();
                });
            }

            componentDidUpdate = async() => {
                let url;
                let wk;
                let wk_dir;

                const searchString = this.props.location.search;
                const params = queryString.parse(searchString);

                if (!params.mailing) {
                    if (params.view) {
                        wk = this.props.match.params.week;
                        wk_dir = await this.constructDir(this.props.match.params.week);
                        if (params.view === 'pdf') {
                            url = this.urlAPI + "/" + wk_dir + "/wk" + wk + ".php?origin=site";
                        } else {
                            url = this.urlAPI + "/newsletters/newsletter.php?mailing=mailing" + wk + "&origin=site";
                        }
                        this.storeUi.openDialogNewsletter(url);
                    } else {
                        this.storeUi.closeDialogNewsletter();
                    }
                } else {
                    if (params.view === 'pdf') {
                        url = this.urlAPI + "/mailings/" + this.storeGeneral.settings_list.latestNewsletter  + ".php?origin=site";
                    } else {
                        url = this.urlAPI + "/newsletters/newsletter.php?mailing=" + this.storeGeneral.settings_list.tagLatestNewsletter + "&origin=site";
                    }
                    this.storeUi.openDialogNewsletter(url);
                }

                if (!this.props.match.params.tab && 
                    !this.props.noScroll &&
                    this.props.match.params.view !== 'client') {
                    //ui.smoothScrollTo(0, 0, 500); 
                }

                this.changeFavIcon();

                //ui.smoothScrollTo(0, 200, 500); 
                //window.scrollTo(0, this.storeUi.freezePos);
            }

            changeFavIcon = () => {
                let link = document.querySelector('link[rel="shortcut icon"]');
              
                if (!link) {
                    link = document.createElement('link');
                    link.id = 'favicon';
                    link.rel = 'shortcut icon';
                    document.head.appendChild(link);
                    link.href = this.urlAPI + "/public/images/favicon/succesboeken-192x192.png?v=3";
                }
            }

            constructDir = async(week) => {
                let newsletters;
                if (this.storeUi.newsletters.length === 0) {
                   newsletters = await this.storeUi.getNewsletters();
                }
                const active_newsletter = this.storeUi.newsletters
                    .filter(newsletter => newsletter.week === week);
                const dir = active_newsletter && active_newsletter[0] && active_newsletter[0].dir;
                return dir;
            }

            handleScroll = () => {
                const position = window.pageYOffset;

                if (this.state.isSticky) {
                    if (position < 250) {
                        this.setState({
                            isSticky: false
                        })
                    } 
                } else {               
                    if ((position >= 250)) {
                        this.setState({
                            isSticky: true
                        })
                    } 
                }
            }

            setNavTab = () => {
                let idx;
                let id;

                if (this.props.nav_name) {
                    const nav_name = this.props.nav_name;

                    idx = this.storeUi.main_nav
                        .findIndex((item) => item.name === this.props.nav_name);

                    if (idx > -1) {
                        this.storeUi.setActiveMainNav(this.storeUi.main_nav[idx].id);
                        this.storeUi.setActiveSubNav(0);
                    } else {
                        idx = this.storeUi.sub_nav
                            .findIndex((item) => item.name === this.props.nav_name);
                        id = (idx > -1) ? this.storeUi.sub_nav[idx].id : 0;
                        this.storeUi.setActiveMainNav(0);
                        this.storeUi.setActiveSubNav(id);                    
                    };
                } else {
                    this.storeUi.setActiveMainNav(0);
                    this.storeUi.setActiveSubNav(0);  
                }
            }
      
            render() {
                const class_feature = this.storeUi.checkFeature('SHOP_ALL') ? '--feature-all' : ''
                const class_page = this.props.class_page ? this.props.class_page : ''
                const pos_top = this.storeUi.app_interface.height_header

                return (
                    <React.Fragment>
                        <MetaTags>
                            {this.storeUi.checkFeature('SHOP_ALL') && this.storeGeneral.shop === 'SHOP_ALL'
                            ?   <React.Fragment>
                                    <title>Succesboeken.nl - Een dag zonder boek ...</title>
                                    <meta property="og:title" content={'Succesboeken.nl - Een dag zonder boek ...'} />
                                </React.Fragment>
                            :   <React.Fragment>
                                    <title>Succesboeken.nl - De grootste website voor zelfhulpboeken en -producten</title>
                                    <meta property="og:title" content={'Succesboeken.nl - De grootste website voor zelfhulpboeken en -producten'} />
                                </React.Fragment>
                            }
                        </MetaTags>

                        {(this.props.type === 'default' || this.props.type === 'custom-authors' || this.props.type === 'error') &&
                            <div 
                                className={"content-wrapper" + ' ' + class_feature}
                                style = {{marginTop: rdd.isMobile ? 0 : pos_top}}
                            >
                                {!this.storeUi.search_overlay && this.props.sticky &&
                                    <div className={"content-wrapper__segment--sticky" + (this.state.isSticky ? " --active" : "")}>
                                        <BlockBookActions
                                            view = {["wishlist", "cart"]}
                                            display = {"full"}
                                        />
                                    </div>
                                }
                            
                                <SidebarLeft 
                                    template = {this.props.type} 
                                />
                                
                                <SidebarRight />
                
                                <div className="content-wrapper__middle">
                                    <div className="content-wrapper__content">
                                        {this.props.header &&
                                            <PageHeader
                                                text = {this.props.header}
                                                textCustom = {this.props.headerCustom 
                                                    ?   this.props.headerCustom 
                                                    :   ''
                                                }
                                                personalized = {this.props.personalized}
                                                subtitle = {this.props.subtitle}
                                            />
                                        }
                                        {this.props.children}
                                    </div>
                                </div>

                                {this.storeUi.checkFeature('SHOP_ALL') &&
                                    <div    
                                        className={"search-overlay__bg" + ((this.storeUi.search_overlay && (this.storeUi.search_value.trim().length >= 3 || content.searchChars(this.storeUi.search_value.trim()))) ? ' --active' : '')} 
                                    />
                                }
                                
                                <SearchOverlay
                                    searchInput = {this.storeUi.search_value}
                                    searchInputFocus = {this.storeUi.search_input_focus}
                                    inputRef = {this.storeUi.search_inputRef}
                                    searchShop = {this.storeGeneral.searchShop}
                                />
                            </div>
                        }

                        {this.props.type === 'single-column' &&
                            <div 
                                className={"content-wrapper" + ' ' + class_feature + ' ' + class_page}
                                style = {{marginTop: rdd.isMobile ? 0 : pos_top}}
                            >
                                <div className="content-wrapper__middle">
                                    <div className="content-wrapper__content">
                                        {this.props.header &&
                                            <PageHeader
                                                text = {this.props.header}
                                                textCustom = {this.props.headerCustom 
                                                    ?   this.props.headerCustom 
                                                    :   ''
                                                }
                                                personalized = {this.props.personalized}
                                            />
                                        }    
                                        {this.props.children}
                                    </div>
                                </div>
                            </div>
                        }

                        {this.props.type !== 'error' && this.props.type !== 'single-column' &&
                            ((isIOS || isBrowser) && 
                                (this.props.history && this.props.history.location.pathname !== "/")) &&
                                (this.storeUi.templateView !== "clean") &&
                                (!this.storeUi.search_input_focus || !this.storeUi.search_overlay) &&
                                    <ButtonBack />
                        }
                        
                        {(!this.storeUi.search_input_focus || !this.storeUi.search_overlay) &&
                            <ScrollerTop />
                        }
                    </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(Template);
