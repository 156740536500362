import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withRouter } from "react-router-dom";

import ImgCover from '../../elements/img-cover';
import BookLang from '../../elements/book-lang';
import BlockBookStock from '../block/block-book-stock';

import * as utilities from '../../utilities/formats';
import * as content from '../../utilities/content';

const SearchBlockTitle = inject("stores") (
    observer (
        class SearchBlockTitle extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeSearch = this.props.stores.storeSearch;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeStock = this.props.stores.storeStock;

                this.minLength = 5;

                this.state = {
                    item: {},
                    sku: '',
                    stock_code: 0,
                    hasTopItem: false,
                    elevatedHeader: 'Zocht je deze titel:'
                };
            }

            componentDidMount = async() => {
                let item;
                let searchValue = this.props.searchValue.toLowerCase();

                item = await this.storeSearch.getSearchTitle(searchValue, 'sb');
                if (!item) {
                    item = await this.storeSearch.getSearchTitle(searchValue, 'all');
                }

                if (item) {
                    this.storeSearch.setSearchResultsTitle(true);
                    this.setState({
                        item: item,
                        sku: item.sku,
                        stock_code: item.stock_code
                    })
                } else {
                    this.storeSearch.setSearchResultsTitle(false);
                    this.setState({
                        item: {},
                        sku: '',
                        stock_code: 0
                    })
                }
            }

            componentDidUpdate = async(prevProps) => {
                let item;

                if (prevProps.searchValue !== this.props.searchValue) {
                    let searchValue = this.props.searchValue.toLowerCase();  
                
                    item = await this.storeSearch.getSearchTitle(searchValue, 'sb');
                    if (!item) {
                        item = await this.storeSearch.getSearchTitle(searchValue, 'all');
                    }

                    if (item) {
                        this.storeSearch.setSearchResultsTitle(true);
                        this.setState({
                            item: item,
                            sku: item.sku,
                            stock_code: item.stock_code
                        })
                    } else {
                        this.storeSearch.setSearchResultsTitle(false);
                        this.setState({
                            item: {},
                            sku: '',
                            stock_code: 0
                        })
                    }
                }
            }

            getElevatedHeader = async(sku) => {
                let elevatedHeader = 'aanbevolen';
                let header = await this.storeUi.getElevatedSku(sku);
                
                if (header['elevatedHeader'] !== null) {
                    elevatedHeader = header['elevatedHeader'];
                }
                
                return elevatedHeader;
            }

            handleOnClick = (result) => {
                this.storeUi.setCurrentBook(result.sku);

                if (this.props.shop) {
                    this.storeGeneral.setActiveShop(this.props.shop);
                }

                if (this.props.shop) {
                    if (this.props.shop === 'SB') {
                        this.storeUi.setActiveToggleNav(2);
                        this.storeGeneral.setActiveShop('SHOP_SB');
                    }
                    if (this.props.shop === 'ALL') {
                        this.storeUi.setActiveToggleNav(3);
                        this.storeGeneral.setActiveShop('SHOP_ALL');
                    }
                }
                
                let url;
                if (parseInt(this.storeGeneral.settings_list.seoTitles) === 1) {
                    //url = "/book/" + result.sku + "/" + id;
                    url = "/book/" + result.sku;
                } else {
                    url = "/book/" + result.sku;
                }
                this.props.history.push({
                    sku: result.sku,
                    pathname: url
                })

                this.storeUi.setSearchOverlay(false);
            }

            constructAuthor(firstname, prefix, lastname) {
                let author = '';

                if (this.state.item.author_firstname) {
                    author = this.state.item.author_firstname;
                    if (this.state.item.author_prefix) {
                        author = author + ' ' + this.state.item.author_prefix;
                    }
                    if (this.state.item.author_lastname) {
                        author = author + ' ' + this.state.item.author_lastname;
                    }
                } else {
                    if (this.state.item.author_lastname) {
                        author = this.prstateops.item.author_lastname;
                    }
                    if (this.state.item.author_prefix) {
                        author = this.state.item.author_prefix + ' ' + author;
                    }
                }

                return author;
            }

            convertString(str) {
                let cStr = '';

                cStr = content.revertHTML(str);
                return cStr;
            }

            render() {
                return (
                    ('sku' in this.state.item) &&
                    <React.Fragment>
                        <ul 
                            className = "list--overlay2 --top-match --elevated"
                        > 
                            <li 
                                className={this.state.elevatedHeader ? "--elevated --link" : "--topitem --link"}
                                onClick = {(e) => this.handleOnClick(this.state.item)}
                                data-content = {this.state.elevatedHeader}
                            >
                                <div 
                                    className="search-item"
                                >
                                    <div 
                                        className="search-item__img"
                                    >
                                        <ImgCover
                                            sku = {this.state.item.sku}
                                            soldout = {(this.state.item && parseInt(this.state.item.stock) === 2) ? true : false}
                                        />
                                        {(this.state.item && parseInt(this.state.item.stock) === 2) &&
                                            <span className="cover__soldout">Uitverkocht</span>
                                        }
                                    </div>
                                    <div 
                                        className="search-item__data"
                                    >
                                        <div>
                                            {this.state.item.title &&
                                                <span
                                                    className="search-item__data--title"
                                                    dangerouslySetInnerHTML={{ __html: utilities.capitalizeTitle(this.state.item.title)}}
                                                />
                                            }
                                            <span
                                                dangerouslySetInnerHTML={{ __html: this.constructAuthor(this.state.item.author_firstname, this.state.item.author_prefix, this.state.item.author_lastname)}}
                                            />
                                        </div>
                                        <div 
                                            className="search-item__specs"
                                        >
                                            <ul className="block--specs-summary__list">
                                                <li className="block--specs-summary__listitem">
                                                    <BookLang
                                                        book = {this.state.item}
                                                    />
                                                </li>
                                                <li className="block--specs-summary__listitem">{this.state.item.version}</li>
                                                <li className="block--specs-summary__listitem">ISBN: {this.state.item.sku}</li>
                                            </ul>

                                            <BlockBookStock 
                                                sku = {this.state.sku}
                                                book = {this.state.item}
                                                stock_code = {this.state.stock_code}
                                            ></BlockBookStock>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </React.Fragment> 
                )
            }
        }
    )
)

export default withRouter(SearchBlockTitle);
